/**
 * Apply this style for Internet Explorer 10-11 only.
 * Credit: https:// stackoverflow.com/questions/28417056/how-to-target-only-ie-any-version-within-a-stylesheet
 */
@mixin ie10-11() {

    @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
        @content;
    }
}

/**
 * Apply this style for Edge only.
 * Credit: https: //stackoverflow.com/questions/32940965/how-to-target-microsoft-edge-with-css
 */
@mixin edge() {
    @supports (-ms-ime-align: auto) {
        @content;
    }
}

/**
 * Apply this style to Firefox only.
 * Credit: https:// stackoverflow.com/questions/952861/targeting-only-firefox-with-css?noredirect=1&lq=1
 */
@mixin firefox() {
    @-moz-document url-prefix() {
        @content;
    }
}