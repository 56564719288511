@font-face {
    font-family: 'Hind Siliguri';
    src: url('https://cdn2.hubspot.net/hubfs/4822068/fonts/Hind_Siliguri/HindSiliguri-Light.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Hind Siliguri';
    src: url('https://cdn2.hubspot.net/hubfs/4822068/fonts/Hind_Siliguri/HindSiliguri-Light.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Hind Siliguri';
    src: url('https://cdn2.hubspot.net/hubfs/4822068/fonts/Hind_Siliguri/HindSiliguri-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Hind Siliguri';
    src: url('https://cdn2.hubspot.net/hubfs/4822068/fonts/Hind_Siliguri/HindSiliguri-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Hind Siliguri';
    src: url('https://cdn2.hubspot.net/hubfs/4822068/fonts/Hind_Siliguri/HindSiliguri-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Hind Siliguri';
    src: url('https://cdn2.hubspot.net/hubfs/4822068/fonts/Hind_Siliguri/HindSiliguri-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Hind Siliguri';
    src: url('https://cdn2.hubspot.net/hubfs/4822068/fonts/Hind_Siliguri/HindSiliguri-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Hind Siliguri';
    src: url('https://cdn2.hubspot.net/hubfs/4822068/fonts/Hind_Siliguri/HindSiliguri-Bold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Hind Siliguri';
    src: url('https://cdn2.hubspot.net/hubfs/4822068/fonts/Hind_Siliguri/HindSiliguri-Bold.ttf');
    font-weight: 900;
    font-style: normal;
}