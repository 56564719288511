* {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    height: auto;
}

body {
    margin-top: $marginBodyTop;
    font-family: 'Hind Siliguri', serif;
    background-color: $white;
    @include media-breakpoint-up(sm) {
        margin-top: $marginBodyTopSm;
    }
}

ul {
    padding-left: 2.5rem;
}

a {
    color: $info;
}

.splash {
    font-size: 1.25em;
    position: relative;
    //height: 32.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    @include ie10-11() {
        display: block;
    }
    
    &.sliver {
        height: auto;
    }

    h1, h2, h3, h4, h5, p {
        text-shadow: .15rem .15rem .3rem #000000;
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: hsla(0, 0%, 0%, 0.5);
    }
}

.section-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

a.text-light {
    text-decoration: underline;
}