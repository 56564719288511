/**
 * This file pulls in any global styles we are using on the main site
 * and combines them into a separate global styles file for inclusion
 * on our Hubspot site.
 */

/**
 * Fonts
 */
@import "../../partials/hs-fonts";

/*
 * Global
 */
@import "../../partials/variables";
@import "../../partials/mixins";

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome.scss";

@import "../../partials/global";
@import "../../partials/utilities";
@import "../../partials/forms";