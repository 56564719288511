.button-border {
    border: $buttonBorder;
    &:hover {
        border: $buttonBorder;
    }
}

.link-dark {
    &:hover {
        color: lighten($linkColor, 5%);
    }
}

/**
 * Colors
 */
@each $name, $color in $colors {
    .#{$name} {
        color: $color
    }

    .background-#{$name} {
        background-color: $color;
    }
}