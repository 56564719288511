/*
 * Colors
 */
$brandDarkGreen: #049a52;
$brandLightGreen: #84c455;
$brandDarkBlue: #424663;
$brandLightBlue: lighten($brandDarkBlue, 15%);

$white: #FFF;
$offWhite: #EFEFEF;
$gray: #AAA;
$lightGray: #CCC;
$darkGray: #444;
$black: #000;

$colors: (
    brandDarkGreen: $brandDarkGreen,
    brandLightGreen: $brandLightGreen,
    brandDarkBlue: $brandDarkBlue,
    brandLightBlue: $brandLightBlue,
    white: $white,
    offWhite: $offWhite,
    gray: $gray,
    lightGray: $lightGray,
    darkGray: $darkGray,
    black: $black
);

/*
 * Bootstrap Colors
 */
$primary: $brandDarkGreen !default;
$secondary: $brandDarkBlue !default;
$success: $brandDarkGreen !default;
$info: #7e8bb8 !default;

/**
 * Fonts
 */
$bodyFontColor: #202020;

/**
 * Margins
 */
$marginBodyTop: 56px;
$marginBodyTopSm: 78px;

/*
 * Borders
 */
$buttonBorder: 1px solid $white;

/**
 * Links
 */
$linkColor: $brandDarkGreen;

/*
 * Layout
 * By default this will match Bootstrap's container widths,
 * but we are setting a separate value in case we need to
 * change the container width. This way, we don't have to
 * hack Bootstrap itself in order to do this.
 */
$containerWidth: auto;
@media (min-width: 576px) { $containerWidth: 540px; }
@media (min-width: 768px) { $containerWidth: 720px; }
@media (min-width: 992px) { $containerWidth: 960px; }
@media (min-width: 1200px) { $containerWidth: 1140px; }